import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';

function PaymentTab({ title, value}) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value}</h4>
        </div>
    );
}

function Payment({ Payments }) {
    const { paymentId, orderAmount, orderDate, paymentMethod, jobDetail, paymentTime, paymentStatus, paymentType, orderDropOffDate, items, SKU } = Payments;

    const [showMainPopup, setShowMainPopup] = useState(false);

    const openMainPopup = () => {
        setShowMainPopup(true);
    };
    const closeMainPopup = () => {
        setShowMainPopup(false);
    };

    return (
        <li> 
            <Link 
                onClick= {openMainPopup}
                 className='order-box flex-box align-center space-between'>
                <PaymentTab title="ID" value={paymentId} />
                <PaymentTab title="Order Amount" value={orderAmount} />
                <PaymentTab title="Order Date" value={orderDate} />
                <PaymentTab title="Payment Method" value={paymentMethod} />
                <PaymentTab title="Job Detail" value={jobDetail} />
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
            {showMainPopup && (
                    <div className="popup-outer-box">
                        <div className='popup-box user-edit-popup payment-popup'>
                            <button className='popup-close-btn' onClick={closeMainPopup}><img src={popupCloseIcon} alt="" /></button>
                            <div className="popup-edit-box ">
                                <div className="inner-box flex-box space-between">
                                    <div className="field-box">
                                        <h6>Order Id</h6>
                                        <h4>{paymentId}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Order Amount</h6>
                                        <h4>{orderAmount}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Order Date</h6>
                                        <h4>{orderDate}</h4>
                                    </div>
                                </div>
                                <div className="inner-box flex-box space-between">
                                    <div className="field-box">
                                        <h6>Payment Method</h6>
                                        <h4>{paymentType}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Payment Time</h6>
                                        <h4>{paymentTime}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Payment Status</h6>
                                        <h4>{paymentStatus}</h4>
                                    </div>
                                </div>
                                <div className="inner-box flex-box space-between">
                                    <div className="field-box">
                                        <h6>SKU</h6>
                                        <h4>{SKU}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Items</h6>
                                        <h4>{items}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Order Drop Off Date</h6>
                                        <h4>{orderDropOffDate}</h4>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="bottom-box">
                                <h6>Job Details</h6>
                                <h4>Standard-Trouser, Reduce Waist</h4>
                                <h4>Premium-Shirt, Tuck in from inside</h4>
                                <h4>Premium-Knitwear, Waist In</h4>
                            </div>
                            <div className="bottom-save-box flex-box space-between align-center">
                                <button className='save-info-btn'>Save</button>
                            </div>
                   
                        </div>
                    </div>
                )}
        </li>
    );
}


export default Payment