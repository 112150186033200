import React, { useState, useEffect } from "react";
import dropDownIcon from "../../assets/img/dropdown-icon.webp";
import itemVideoPoster from "../../assets/img/video-poster.webp";
import itemImage from "../../assets/img/item-detail-img.webp";
import deliveryEstimateIcon from "../../assets/img/delivery-estimate-icon.webp";
import statusPendingIcon from "../../assets/img/status-pending-icon.webp";
import { Link } from "react-router-dom";
const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday, 5 represents Friday
  let pickupOffset = 0;

  // Determine the initial offset based on the delivery day
  switch (deliveryDay) {
      case 1: // Monday
          pickupOffset = 10;
          break;
      case 3: // Wednesday
          pickupOffset = 12;
          break;
      case 5: // Friday
          pickupOffset = 10;
          break;
      default:
          return null; // Handle other days if necessary
  }

  const pickupDate = new Date(deliveryDate);

  // Add offset to delivery date, skipping Saturdays and Sundays
  while (pickupOffset > 0) {
      pickupDate.setDate(pickupDate.getDate() + 1);
      const dayOfWeek = pickupDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
          pickupOffset--;
      }
  }

  return pickupDate.toISOString(); // Convert to ISO format for consistency
};


function hasCompleteEmailStatus(order) {
  return order?.email_status?.some((status) => status?.emailType === "Complete");
}

function ItemDetail({ itemNo, title, data, item, deliverydate, deliveryInfo, order }) {
  const tobeDeliveredDate = calculatePickupDate(deliverydate);
  let completedOrder = order?.email_status?.filter((status) => status?.emailType === "Complete");
  let orderStatus = hasCompleteEmailStatus(order)
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  
  return (
    <li className="item-detail-box">
      <div
        className="head-box flex-box space-between align-center"
        onClick={toggleDetails}
      >
      </div>
      <ul
        className={`item-detail-inner-box-listing flex-box ${
          showDetails ? "" : "show"
        }`}
      >
        <li className="item-detail-inner-box">
          <div className="field-box">
            <h6>Sub-Order Id</h6>
            <h4>{data?.order_id_custom}</h4>
          </div>
          {/* <div className="field-box">
                            <h6>SKU</h6>
                            <h4>BL123</h4>
                        </div> */}
          <div className="field-box">
            <h6>Item Name</h6>
            <h4>{data?.item_details?.name}</h4>
          </div>
          <div className="field-box">
            <h6>Category</h6>
            <h4>{data?.item_details?.pricing_plan}</h4>
          </div>
          <div className="field-box">
            <h6>Service Type</h6>
            <h4>{data?.service_details?.selectedService}</h4>
          </div>
          <div className="field-box">
            <h6>Service Price</h6>
            <h4>$ {data?.item_details?.pricing_plan == "Standard" ? data?.service_details?.standard_price : data?.service_details?.premium_price}</h4>
          </div>
        </li>

        <li className="item-detail-inner-box flex-box space-between">
          <div className="text-box">
            {/* <div className="field-box">
              <h6>Detail Mode</h6>
              <h4>{data?.service_details?.selectedService}</h4>
            </div> */}
            <div className="field-box">
              <h6>Service Name</h6>
              <h4>{data?.service_details?.name}</h4>
            </div>
            <div className="field-box">
              <h6>Job Description</h6>
              <h4>{data?.item_details?.describe != undefined ? data?.item_details?.describe : data?.item_details?.description}</h4>
            </div>
            <div className="field-box">
              {data?.service_details?.selectedService == "Alteration" && (
                <>
                  <h6>Notes For Tailor</h6>
                  <h4>{data?.service_details?.item_notes}</h4>
                </>
              )}
              {data?.service_details?.selectedService == "Repairing" && (
                <>
                  <h6>Repair Details Details</h6>
                  <h4>
                    <span>
                      Repair Location :{" "}
                      {data?.service_details?.repair_details?.location}
                    </span>
                    <br />
                    <span>
                      Repair Description :{" "}
                      {data?.service_details?.repair_details?.description}
                    </span>
                  </h4>
                </>
              )}
              {data?.service_details?.selectedService == "Alteration" && (
              <div className="field-box" style={{marginTop : "0.4rem"}}>
                <h6>Alteration Details</h6>
                <h4>{data?.service_details?.alterationDetails?.type} : {data?.service_details?.alterationDetails?.measurement}</h4>
              </div>
              )}
            </div>
            {/* <div className="field-box">
              <h6>Can we leave the order with a neighbor?</h6>
              <h4>{deliveryInfo?.leavewithneighbour == true ? "Yes" : "No" || "Yes"}</h4>
            </div>
            <div className="field-box">
              <h6>Info 1 (Is there a safe place to leave the delivery ?)</h6>
              <h4>{deliveryInfo?.basic_instruction}</h4>
            </div>
            <div className="field-box">
              <h6>Info 2 (Extra delivery instructions)</h6>
              <h4>{deliveryInfo?.extra_instruction}</h4>  
            </div> */}
          </div>
          {/* <div className="img-box">
            <h6>Supporting Images & Videos</h6>
            <div className="inner-img-box flex-box space-between">
              <video poster={itemVideoPoster} src=""></video>
              <img src={itemImage} alt="" />
            </div>
          </div> */}
        </li>
        <div className="item-bottom-bar flex-box space-between">
          <Link>
            <h6>Need Help?</h6>
          </Link>
          <div className="status-and-date-box">
            <h6>
              <span>
                <img src={statusPendingIcon} alt="" />
              </span>
              Status :
            </h6>
            <h4>{orderStatus ? 'Delivered' : 'Open'}</h4>
            <h6>
              <span>
                <img src={deliveryEstimateIcon} alt="" />
              </span>
              Drop-off Date :
            </h6>
            <h4>
              {orderStatus && completedOrder && completedOrder.length != 0 ? new Date(completedOrder[0]?.sendDate).toLocaleString("en-US", {day: "numeric", month: "long", weekday: "long" }) : new Date(tobeDeliveredDate).toLocaleString("en-US", {day: "numeric", month: "long", weekday: "long" })}
            </h4>
          </div>
        </div>
      </ul>
    </li>
  );
}

export default ItemDetail;
