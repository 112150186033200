import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/css/App.css";
import DashBoard from "./pages/DashBoard";
import CommonPage from "./pages/CommonPage";
import OrderDetail from "./pages/OrderDetail";
import LogIn from "./pages/LogIn";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import UnderConstruction from "./components/UnderConstruction";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Toaster />
        <Routes>
          {/* <Route path="/*" element={<UnderConstruction />} /> */}
          <Route path="/" element={<LogIn />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/past" element={<DashBoard />} />
          <Route path="/canceled" element={<DashBoard />} />
          <Route exact path="/orders/:orderId" element={<OrderDetail />} />
          <Route path="/payment-methods" element={<CommonPage />} />
          <Route path="/my-address" element={<CommonPage />} />
          <Route path="/contact-us" element={<CommonPage />} />
          <Route path="/settings" element={<CommonPage />} />
          <Route path="/log-in" element={<LogIn />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
