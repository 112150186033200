import React from 'react'
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';
const calculatePickupDate = (deliveryDate) => {
    const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday
    const pickupOffset = deliveryDay === 1 ? 10 : deliveryDay === 3 ? 12 : 0; // Offset based on delivery day
    const pickupDate = new Date(deliveryDate);
    pickupDate.setDate(pickupDate.getDate() + pickupOffset); // Add offset to delivery date
    return pickupDate.toISOString(); // Convert to ISO format for consistency
  };
function OrderTab({ title, value, status }) {
    let statusIcon = null;

    if (status === "Task In-progress") {
        statusIcon = <span className="status-dot orange"></span>;
    } else if (status === "Completed") {
        statusIcon = <span className="status-dot green"></span>;
    }
    else if (status === "Pending Pickup") {
        statusIcon = <span className="status-dot red"></span>;
    }
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value}{statusIcon}</h4>
        </div>
    );
}

function hasCompleteEmailStatus(order) {
    return order?.email_status?.some((status) => status?.emailType === "Complete");
  }
  
  function hasWithFixerEmailStatus(order) {
    return order?.email_status?.some((status) => status?.emailType == "With Fixer");
  }

function Order({ order, index }) {
    let itemCount = order?.jobs?.length;
    const todeliverdate = calculatePickupDate(order?.collection_date)
    const total = order?.jobs?.map((item,index) => {
        return item?.item_details?.pricing_plan == "Standard" ? item?.service_details?.standard_price : item?.service_details?.premium_price
    });
    let subtotal = total.reduce((acc, currentValue) => acc + Number(currentValue), 0);
    let percentae = 10.35;
    let totalTax = (percentae / 100) * subtotal;

    let orderStatus = hasCompleteEmailStatus(order)
    let completedOrder = order?.email_status?.filter((status) => status?.emailType === "Complete");
    let withFixerStatus = hasWithFixerEmailStatus(order)

    return (
        <li>
            <Link
                to={{
                    pathname: `/orders/${encodeURIComponent(order?._id)}`,
                    state: { _id : order?._id, itemCount, amount :order?.order_items[index]?.pricing_plan == "Standard" ? order?.services[0]?.standard_price : order?.services[0]?.premium_price }
                }}
                onClick={() => {
                    sessionStorage.setItem('currentOrder', JSON.stringify({ _id : order?._id, itemCount, amount : order?.order_items[index]?.pricing_plan == "Standard" ? order?.services[0]?.standard_price : order?.services[0]?.premium_price }));
                }}
                className='order-box flex-box align-center space-between'>
                <OrderTab title="ID" value={order?.custom_order_id} />
                <OrderTab title="Order Date" value={new Date(order?.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long' })} />
                <OrderTab title="Item Count" value={order?.jobs?.length} />
                <OrderTab title="Amount" value={(subtotal + totalTax + 5).toFixed(2)} />
                <OrderTab title="Pickup Date" value={new Date(order?.collection_date).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long' })} />
                <OrderTab title="Status" value={orderStatus ? 'Delivered' : withFixerStatus ? 'With-Fixer' : 'Pending'} status={order?.status} />
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
        </li>
    );
}


export default Order