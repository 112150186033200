import React, {useState} from 'react'
import settingIcon from '../../assets/img/menu-icon-5.webp'
import logOutIcon from '../../assets/img/log-out-icon.webp'
import circleBtn from '../../assets/img/btn-circle.png'


function SettingsTab() {

    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showTerms, setShowTerms] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [marketingEmails, setMarketingEmails] = useState(true);
    const [promotionalEmails, setPromotionalEmails] = useState(false);
    const [accountRecommendation, setAccountRecommendation] = useState(true);

    const handlePrivacyTab = () => {
        setShowPrivacy(true)
        setShowNotification(false)
        setShowTerms(false)
    }
    const handleTermsTab = () => {
        setShowPrivacy(false)
        setShowNotification(false)
        setShowTerms(true)
    }
    const handleNotificationTab = () => {
        setShowPrivacy(false)
        setShowNotification(true)
        setShowTerms(false)
    }
    const handleMarketing = () => {
        setMarketingEmails(!marketingEmails)
    }
    const handlePromotional = () => {
        setPromotionalEmails(!promotionalEmails)
    }
    const handleRecommendation = () => {
        setAccountRecommendation(!accountRecommendation)
    }

  return (
        <div className="tab-detail-outer-box settings-tabs-box">
            <h6 className='tab-detail-heading'> <strong><img src={settingIcon} alt="" /></strong> Settings </h6>
            <div className="settings-tabs">
                <div className="head-box flex-box space-between align-center">
                    <div className="tab-menu-box flex-box align-center">
                        <div className={`tab-menu ${ showPrivacy ? 'active' : '' } `} onClick={handlePrivacyTab}>
                            <h6 className="tab-detail-heading" >Privacy Policy</h6>
                        </div>
                        <div className={`tab-menu ${ showTerms ? 'active' : '' } `} onClick={handleTermsTab}>
                            <h6 className="tab-detail-heading">Terms & Conditions</h6>
                        </div>
                        <div className={`tab-menu ${ showNotification ? 'active' : '' } `} onClick={handleNotificationTab}>
                            <h6 className="tab-detail-heading">Marketing & Notification</h6>
                        </div>
                    </div>
                    <div className="logout-box">
                        <h6 className='tab-detail-heading' > <strong><img src={logOutIcon} alt="" /></strong> Logout</h6>
                    </div>
                </div>
                <div className="setting-content-box">
                    {showPrivacy && (
                        <div className="settings-inner-tab privacy-policy-box setting-content">
                           <p>The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website</p>
                           <p>The term ‘Company’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is [Office Address]. The term ‘you’ refers to the user or viewer of our website.</p>
                           <h4>The use of this website is subject to the following terms of use:</h4>
                           <ul>
                                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                                <li>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                                <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                                <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                           </ul>
                        </div>
                    )}
                    {showTerms && (
                        <div className="settings-inner-tab tems-box">
                           <div className="settings-inner-tab privacy-policy-box setting-content">
                           <p>The Website Owner, including subsidiaries and affiliates (“Website” or “Website Owner” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website</p>
                           <p>The term ‘Company’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is [Office Address]. The term ‘you’ refers to the user or viewer of our website.</p>
                           <h4>The use of this website is subject to the following terms of use:</h4>
                           <ul>
                                <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                                <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                                <li>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                                <li>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                                <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                           </ul>
                        </div>
                        </div>
                    )}
                    {showNotification && (
                        <div className="settings-inner-tab notification-box">
                           <h6>Notification</h6>
                           <h4 className={` ${marketingEmails ? '' : 'deactive-btn'}`} onClick={handleMarketing} > <span> <img src={circleBtn} alt="" /> </span>  Marketing Emails</h4>
                           <h4 className={` ${promotionalEmails ? '' : 'deactive-btn'}`} onClick={handlePromotional} > <span> <img src={circleBtn} alt="" /> </span>  Promotional Emails </h4>
                           <h4 className={` ${accountRecommendation ? '' : 'deactive-btn'}`} onClick={handleRecommendation} > <span> <img src={circleBtn} alt="" /> </span>  There are recommendation for my account </h4>
                        </div>
                    )}
                </div>
            </div>
        </div>
  )
}

export default SettingsTab