import React, { useState } from "react";
import addressIcon from "../../assets/img/menu-icon-3.webp";
import Addresses from "../module-tabs-detail/Addresses";
import axios from "axios";
import { useQuery } from "react-query";

function AllAddressTab() {
  const [AddressData, setAddressData] = useState(null);
  const getAllOrders = async ({ queryKey }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}order/allcustomerorders/${queryKey}`
    );
    return response.data;
  };
  const [usersData, setUsersData] = useState(
    () => JSON.parse(localStorage.getItem("users")) || ""
  );
  const { data, isLoading } = useQuery({
    queryKey: [usersData?._id],
    queryFn: getAllOrders,
    onSuccess: (response) => {
      setAddressData(
        response.map((order, index) => {
          return {
            srNum: index + 1,
            type: "Home",
            streetName: order?.shipping_address?.addressText || "NA",
            locality: order?.shipping_address?.line1 || "NA",
            pinCode: order?.shipping_address?.code || "NA",
            number: usersData?.phone || "NA",
            streetNumber: order?.shipping_address?.line2 || "NA",
            neighborhoodName: order?.shipping_address?.addressText || "NA",
            city: order?.shipping_address?.city || "NA",
            State: order?.shipping_address?.state || "NA",
            zipCode: order?.shipping_address?.code || "NA",
            countyName: "USA",
            CountryFIPS: "" || "NA",
          };
        })
      );
    },
  });

  console.log(usersData);

  //   const AddressData = [
  //     {
  //       srNum: "01",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "02",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "03",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "04",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "05",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "06",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06075",
  //     },
  //     {
  //       srNum: "07",
  //       type: "Home",
  //       streetName: "Denny Way",
  //       locality: "Beacon Hill",
  //       pinCode: "98039",
  //       number: "+1(206) 555-3890",
  //       streetNumber: "125",
  //       neighborhoodName: "Unique Square",
  //       city: "Seattle",
  //       State: "Washington",
  //       zipCode: "94108",
  //       countyName: "Seattle",
  //       CountryFIPS: "06076",
  //     },
  //   ];

  return (
    <div className="tab-detail-outer-box payment-listing-box address-listing-box">
      <div className="head-box flex-box space-between">
        <h6 className="tab-detail-heading">
          {" "}
          <strong>
            <img src={addressIcon} alt="" />
          </strong>{" "}
          My Address{" "}
        </h6>
        <div className="right-box flex-box align-center">
          <h4 className="export-csv-btn">Export CSV</h4>
          <select>
            <option value="All Time">All Time</option>
            <option value="This Month">This Month</option>
            <option value="Past 3 Month">Past 3 Month</option>
          </select>
        </div>
      </div>
      <ul className="all-orders-listing">
        <li>
          <div className="order-box address-head-box flex-box align-center space-between">
            <div className="order-detail-box">
              <h4>S. No.</h4>
            </div>
            <div className="order-detail-box">
              <h4>Type</h4>
            </div>
            <div className="order-detail-box">
              <h4>Street Name</h4>
            </div>
            <div className="order-detail-box">
              <h4>Locality</h4>
            </div>
            <div className="order-detail-box">
              <h4>Pin code</h4>
            </div>
            <div className="order-detail-box">
              <h4>Number</h4>
            </div>
            <div className="order-cta-box empty-box"></div>
          </div>
        </li>
        {AddressData!= null && AddressData.map((AddressData, index) => (
          <Addresses key={index} Address={AddressData} />
        ))}
      </ul>
    </div>
  );
}

export default AllAddressTab;
