import React from "react";
import OrderItemsListing from "../orders/OrderItemsListing";
import UserOrderDetailBox from "../orders/UserOrderDetailBox";
import ItemDetail from "../orders/ItemDetail";

function OrderDetailTab({ orderId, itemCount, amount, data }) {
  const total = data[0]?.jobs?.map((item,index) => {
    return item?.item_details?.pricing_plan == "Standard" ? item?.service_details?.standard_price : item?.service_details?.premium_price
  });
  let subtotal = total.reduce((acc, currentValue) => acc + Number(currentValue), 0);
  let percentae = 10.35;
  let totalTax = (percentae / 100) * subtotal;
  return (
    <div className="user-order-listing-outer-box">
      <UserOrderDetailBox
        orderId={data[0]?.custom_order_id}
        itemCount={itemCount}
        amount={(subtotal + totalTax + 5).toFixed(2)}
        order={data[0]}
        totalTax={totalTax}
        deliveryInfo={data[0]?.delivery_info}
      />
      {data && data[0]?.jobs?.map((jobs, index) => (
          <>
            <ItemDetail
              key={index}
              itemNo={jobs?._id}
              title={jobs?.selectedType}
              data={jobs}
              item={jobs?.item_details}
              deliverydate={data[0]?.collection_date}
              deliveryInfo={data[0]?.delivery_info}
              order={data[0]}
            />
          </>
        ))}
    </div>
  );
}

export default OrderDetailTab;
