import React from "react";

function UnderConstruction() {
  return (
    <div className="underConstruction">
      <h1>Site Under Construction</h1>
      <h3>We’re Working Hard to Bring You Something Amazing and we will back on 12 July!</h3>
    </div>
  );
}

export default UnderConstruction;
