import React, { useState } from "react";
import Order from "../module-tabs-detail/Orders";
import axios from "axios";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

const getAllOrders = async ({ queryKey }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}order/allcustomerorders/${queryKey}`
  );
  return response.data;
};

function hasCompleteEmailStatus(order) {
  return order?.email_status?.some((status) => status?.emailType === "Complete");
}

function AllOrderTab({ user }) {

  const [usersData,setUsersData] = useState(() => (
    JSON.parse(localStorage.getItem("users")) || ""
  ))

  const [allOrders, setAllOrders] = useState(null);
  const location = useLocation();
  const { data, isLoading } = useQuery({
    queryKey: [usersData?._id],
    queryFn: getAllOrders,
    onSuccess: (response) => {
      
      if (location.pathname == "/dashboard") {
        setAllOrders(response.filter((order) => order.status.toLowerCase() == "pending"));
      }
      if (location.pathname == "/past") {
        let ordersWithCompleteStatus = response.filter((order) => {
          return hasCompleteEmailStatus(order);
        });
        setAllOrders(ordersWithCompleteStatus);
      }
      if (location.pathname == "/canceled") {
        setAllOrders(response.filter((order) => order.status.toLowerCase() == "cancelled"));
      }
    },
  });


  return (
    <div className="tab-detail-outer-box">
      <h6 className="tab-detail-heading">
        {" "}
        Orders <span>OPEN</span>
      </h6>
      <ul className="all-orders-listing">
        {!isLoading && allOrders != null &&
          allOrders.map((order, index) => (
            <Order key={index} index={index + 1} order={order} />
          ))}
      </ul>
    </div>
  );
}

export default AllOrderTab;
