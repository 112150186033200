import React from 'react';
import paymentIcon from '../../assets/img/menu-icon-2.webp'
import Payment from '../module-tabs-detail/Payment';

function AllPaymentTab() {
    const Payments = [
        {
            paymentId: "720/1",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/2",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/3",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/4",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/5",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/6",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
        {
            paymentId: "720/7",
            orderAmount: "$400",
            orderDate: "30th March, Friday",
            paymentMethod: "Card  l  Visa : 3778 **** **** 1234",
            paymentType : "Cash",
            paymentTime: "30 Mar l 02:05 PM",
            paymentStatus : "Successful",
            jobDetail: "Trouser, Reduce Waist ... +3",
            SKU : "03",
            items : "03",
            orderDropOffDate : "2nd Apr, Friday"
        },
    ];

    return (
        <div className="tab-detail-outer-box payment-listing-box">
            <div className="head-box flex-box space-between">
                <h6 className='tab-detail-heading'> <strong><img src={paymentIcon} alt="" /></strong> Past Payments </h6>
                <div className="right-box flex-box align-center">
                    <h4 className='export-csv-btn' >Export CSV</h4>
                    <select>
                        <option value="All Time">All Time</option>
                        <option value="This Month">This Month</option>
                        <option value="Past 3 Month">Past 3 Month</option>
                    </select>
                </div>
            </div>
            <ul className='all-orders-listing'>
                {Payments.map((Payments, index) => (
                    <Payment key={index} Payments={Payments} />
                ))}
            </ul>
        </div>
    );
}

export default AllPaymentTab;
