import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuBox from '../components/common/MenuBox';
import AllOrderTab from '../components/module-tabs/AllOrderTab';
import AllPaymentTab from '../components/module-tabs/AllPaymentTab';
import ContactTab from '../components/module-tabs/ContactTab';
import SettingsTab from '../components/module-tabs/SettingsTab';
import AllAddressTab from '../components/module-tabs/AllAddressTab';

function CommonPage() {
  const location = useLocation();
  const pathSegments = location.pathname.substring(1);

  return (
    <section className='main-dashboard-section'>
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {pathSegments === 'past' || pathSegments === 'canceled'  ? (
            <AllOrderTab />
          ) : pathSegments === 'payment-methods' ? (
            <AllPaymentTab />
          ) : pathSegments === 'my-address' ? (
            <AllAddressTab />
          ): pathSegments === 'contact-us' ? (
            <ContactTab />
          ) : pathSegments === 'settings' ? (
            <SettingsTab />
          ):(
            <h1>Module Not Added</h1>
          )}
        </div>
      </div>
    </section>
  );
}

export default CommonPage;
