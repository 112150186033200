import React, { useEffect, useState } from "react";
import MenuBox from "../components/common/MenuBox";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Order from "../components/module-tabs-detail/Orders";
import addressIcon from "../assets/img/menu-icon-3.webp";

function hasCompleteEmailStatus(order) {
  return order?.email_status?.some((status) => status?.emailType === "Complete");
}

function DashBoard() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("fxryToken");
    if (token) {
      try {
        const verifyUser = async () => {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}users/verifyTokenCustomer`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setUser(response.data);
          // localStorage.setItem("users", JSON.stringify(response.data));
        };
        verifyUser();
      } catch (error) {
        console.error("Error verifying token:", error);
      }
    } else {
      navigate("/log-in");
    }
  }, []);

  const [usersData, setUsersData] = useState(
    () => JSON.parse(localStorage.getItem("users")) || ""
  );
  
  const [allOrders, setAllOrders] = useState(null);
  const location = useLocation();
  const getAllOrders = async () => {
    if(usersData?._id != undefined){
      const response = await axios.get(`${process.env.REACT_APP_API_URL}order/allcustomerorders/${usersData?._id}`);
      if (location.pathname == "/dashboard") {
        // setAllOrders(response?.data?.reverse()?.filter((order) => order?.status?.toLowerCase() == "pending"));
        let ordersWithCompleteStatus = response?.data?.reverse()?.filter((order) => {
          return !hasCompleteEmailStatus(order);
        });
        setAllOrders(ordersWithCompleteStatus);
      }
      if (location.pathname == "/past") {
        let ordersWithCompleteStatus = response?.data?.reverse()?.filter((order) => {
          return hasCompleteEmailStatus(order);
        });
        setAllOrders(ordersWithCompleteStatus);
        // setAllOrders(response?.data?.reverse()?.filter((order) => order?.status?.toLowerCase() == "completed"));
      }
      if (location.pathname == "/canceled") {
        setAllOrders(response?.data?.reverse()?.filter((order) => order?.status?.toLowerCase() == "cancelled") || []);
        console.log(allOrders,'canceled')
      }
    }
  };

  useEffect(() => {
    setAllOrders(null)
    getAllOrders()
  },[location.pathname])  


  return (
    <section className="main-dashboard-section">
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          <div className="tab-detail-outer-box">
            {/* <h6 className="tab-detail-heading">
              {" "}
              Orders <span>OPEN</span>
            </h6> */}
            <div className="head-box flex-box space-between">
              <h6 className="tab-detail-heading">
                Orders{" "}
              </h6>
              <div className="right-box flex-box align-center" style={{cursor : "pointer"}}>
                <h4 className="export-csv-btn">Export CSV</h4>
                {/* <select>
                  <option value="All Time">All Time</option>
                  <option value="This Month">This Month</option>
                  <option value="Past 3 Month">Past 3 Month</option>
                </select> */}
              </div>
            </div>
            <ul className="all-orders-listing">
              {allOrders == null &&
                  <div className="loader-container">
                  <div className="loader">
                    <div className="loader-dot"></div>
                    <div className="loader-dot"></div>
                    <div className="loader-dot"></div>
                  </div>
                </div>
              }
              {allOrders != null &&
                allOrders.map((order, index) => (
                  <Order key={index} index={index + 1} order={order} />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashBoard;
