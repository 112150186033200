import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';

function AddressTab({ value}) {
    return (
        <div className="order-detail-box">
            <h4>{value}</h4>
        </div>
    );
}

function Addresses({ Address }) {
    const { srNum, type, streetName, locality, pinCode, number } = Address;

    const [showMainPopup, setShowMainPopup] = useState(false);

    const openMainPopup = () => {
        setShowMainPopup(true);
    };
    const closeMainPopup = () => {
        setShowMainPopup(false);
    };

    return (
        <li> 
            <Link 
                onClick= {openMainPopup}
                 className='order-box flex-box align-center space-between'>
                <AddressTab value={srNum} />
                <AddressTab value={type} />
                <AddressTab value={streetName} />
                <AddressTab value={locality} />
                <AddressTab value={pinCode} />
                <AddressTab value={number} />
                <div className="order-cta-box">
                    <img src={ctaArrow} alt="" />
                </div>
            </Link>
            {showMainPopup && (
                    <div className="popup-outer-box">
                        <div className='popup-box user-edit-popup payment-popup'>
                            <button className='popup-close-btn' onClick={closeMainPopup}><img src={popupCloseIcon} alt="" /></button>
                            <div className="text-box">
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>Street Number</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.streetNumber}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>Street Name</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{streetName}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>Neighborhood Name</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.neighborhoodName}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>City</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.city}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>State</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.State}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>Zip Code</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.zipCode}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>County Name</h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.countyName}</h4>
                                    </div>
                                </div>
                                <div className="field-box flex-box align-center space-between">
                                    <div className="title-box">
                                        <h6>County FIPS </h6>
                                    </div>
                                    <div className="value-box">
                                        <h4>{Address.CountryFIPS}</h4>
                                    </div>
                                </div>
                            </div>
                            <h6 className='bottom-star-text' >*if You wish to edit or delete the address contact fxry support team </h6>
                        </div>
                    </div>
                )}
        </li>
    );
}


export default Addresses