import React from "react";
import { useLocation } from "react-router-dom";
import MenuBox from "../components/common/MenuBox";
import OrderDetailTab from "../components/module-tabs-detail-page/OrderDetailTab";
import axios from "axios";
import { useQuery } from "react-query";

function OrderDetail() {
  const location = useLocation();
  const orderDetails =
    location.state || JSON.parse(sessionStorage.getItem("currentOrder")) || {};
  // console.log(orderDetails)
  const fetchDetailsOfOrder = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}order/getonebyid/${orderDetails._id}`
    );
    return response.data;
  };

  const { data, isLoading } = useQuery(
    "fetchDetailsOfOrder",
    fetchDetailsOfOrder
  );

  return (
    <section className="main-dashboard-section">
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {!isLoading && (
            <OrderDetailTab
              orderId={orderDetails.orderId}
              itemCount={orderDetails.itemCount}
              amount={orderDetails.amount}
              data={data}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default OrderDetail;
