import React from 'react'
import { Link } from 'react-router-dom';
import contactIcon from '../../assets/img/menu-icon-4.webp'
import trackIcon from '../../assets/img/track-icon.webp'
import contactMail from '../../assets/img/contact-mail.webp'
import contactTel from '../../assets/img/contact-tell.webp'
import contactMes from '../../assets/img/contact-mess.webp'

function ContactTab() {
  return (
        <div className="tab-detail-outer-box contact-tab-box">
            <h6 className='tab-detail-heading'> <strong><img src={contactIcon} alt="" /></strong> Contact Us </h6>
            <div className="contact-inner-tab flex-box space-between">
                <div className="faq-main-box">
                    <h4>
                        Frequently Asked Question
                    </h4>
                    <div className="faq-box">
                        <h4>Q. How does your door-to-door tailoring service work?</h4>
                        <h6>A. We collect, tailor, and deliver your garments at your doorstep, ensuring convenience and quality in every step of the process.</h6>
                    </div>
                    <div className="faq-box">
                        <h4>Q. What types of garments do you tailor?</h4>
                        <h6>A. We tailor all types of garments including suits, shirts, dresses, skirts, pants, jackets, coats, and traditional ethnic wear.</h6>
                    </div>
                    <div className="faq-box">
                        <h4>Q. What areas do you currently serve for door-to-door tailoring?</h4>
                        <h6>A. We currently serve [list of areas] for our door-to-door tailoring services, ensuring convenient and personalized assistance right at your doorstep.</h6>
                    </div>
                    <div className="faq-box">
                        <h4>Q. Are alterations included in the service?</h4>
                        <h6>A. Yes, alterations are included in the service. We provide comprehensive tailoring solutions, including alterations, to ensure the perfect fit for our customers.</h6>
                    </div>
                    <div className="faq-box">
                        <h4>Q. What areas do you currently serve for door-to-door tailoring?</h4>
                        <h6>A. We currently serve [list of areas] for our door-to-door tailoring services, ensuring convenient and personalized assistance right at your doorstep.</h6>
                    </div>
                </div>
                <div className="order-side-box">
                    <div className="track-box flex-box space-between">
                        <div className="img-box">
                            <img src={trackIcon} alt="" />
                        </div>
                        <div className="text-box">
                            <h4>Would You Like <br/>To Track Your Order</h4>
                            <Link className='btn' >
                                <h6>Track Your Order</h6>
                            </Link>
                        </div>
                    </div>
                    <div className="shop-box">
                        <h4>
                            Would You Like <br />
                            To Place A New Order
                        </h4>
                        <Link className='btn' >
                            <h6>Shop Now</h6>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="contact-bottom-box flex-box space-between">
                <div className="contact-box flex-box align-center">
                    <div className="img-box">
                        <img src={contactMail} alt="" />
                    </div>
                    <div className="text-box">
                        <h6>Fxry Support Email</h6>
                        <h4>Customersupport@fxry.com</h4>
                    </div>
                </div>
                <div className="contact-box flex-box align-center">
                    <div className="img-box">
                        <img src={contactTel} alt="" />
                    </div>
                    <div className="text-box">
                        <h6>Fxry Mobile Number</h6>
                        <h4>+1(206) 555-3890, +1(206) 555-3890</h4>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="i-mess-box">
                        <h4> <span><img src={contactMes} alt="" /></span> Send An iMessage</h4>
                    </div>
                </div>

            </div>
        </div>
  )
}

export default ContactTab