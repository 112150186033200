import React from 'react'
import ItemDetail from './ItemDetail';

function OrderItemsListing({data}) {

    const items = [
        { itemNo: 'Item 01', title: 'Blazer - Tuck In From Inside' },
        { itemNo: 'Item 02', title: 'T-Shirt - Tuck In From Inside' },
    ]; 

    console.log(data,'aman')
 
  return (
    <ul className="items-listing">
        {data?.order_items?.map((item, index) => (
        <ItemDetail
            key={index} 
            itemNo={item._id}
            title={item.selectedType}
            data={data}
            item={item}
        />
        ))}
    </ul>
  )
}

export default OrderItemsListing