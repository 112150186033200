import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function LogIn() {
  const navigate = useNavigate()
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerOtp, setCustomerOtp] = useState("");
  const [getOtp, setGetOtp] = useState(false);
  // const formatPhoneNumber = (value) => {
  //   const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-numeric characters
  //   const regex = /^(\+?1[-. ]?)?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
  //   let formattedNumber;

  //   if (regex.test(phoneNumber)) {
  //     formattedNumber = phoneNumber.replace(regex, "+1 ($2) $3-$4");
  //     return { formattedNumber, isValid: true };
  //   } else {
  //     return { formattedNumber: phoneNumber, isValid: false };
  //   }
  // };

  const [errorInfo, setErrorInfo] = useState(null)

  const handleCustomerNumber = (e) => {
    // const { formattedNumber, isValid } = formatPhoneNumber(e.target.value || '');
    setCustomerNumber(e.target.value || '');
  };
  const handleCustomerOtp = (e) => {
    setCustomerOtp(e.target.value);
  };
  const handleGetOtp = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}users/generateCustomerOTP`, {
        customerNumber
      });
  
      if (response.status === 200) {
        setErrorInfo("OTP generated successfully! Check your email.")
        // toast.success("OTP generated successfully! Check your email.");
        setGetOtp(!getOtp);
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status, data } = error.response;
        if (status >= 400 && status < 500) {
          setErrorInfo(`Client error: ${data.message || 'Request failed with status code ' + status}`)
          // toast.error(`Client error: ${data.message || 'Request failed with status code ' + status}`);
        } else if (status >= 500) {
          setErrorInfo(`Server error: ${data.message || 'Request failed with status code ' + status}`)
          // toast.error(`Server error: ${data.message || 'Request failed with status code ' + status}`);
        } else {
          setErrorInfo('An unexpected error occurred')
          // toast.error('An unexpected error occurred');
        }
      } else if (error.request) {
        // The request was made but no response was received
        setErrorInfo('No response received from the server. Please check your network connection.')
        // toast.error('No response received from the server. Please check your network connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorInfo(`Error: ${error.message}`)
        // toast.error(`Error: ${error.message}`);
      }
    }
  };
  

  const customerLoginHandler = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}users/verifyCustomerOtp`, {
        customerNumber,
        customerOtp
      });
  
      if (response.status === 200) {
        localStorage.setItem("fxryToken", response.data.token);
        // toast.success("Login Successfull!")
        localStorage.setItem("users", JSON.stringify(response.data.user));
        navigate("/dashboard");
      }
    } catch (error) {
      setErrorInfo("Invalid OTP or customer number")
      // toast.error("Invalid OTP or customer number");
      // Handle specific error cases if needed
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Error response:", error.response);
        // Add more specific error handling based on status code if needed
        if (error.response.status === 401) {
          // Unauthorized
          // console.log("Invalid OTP or customer number");
          setErrorInfo("Invalid OTP or customer number")
        } else {
          console.log("An error occurred:", error.response.data.message || "Unknown error");
          setErrorInfo("Invalid OTP or customer number")
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
      } else {
        // Something else caused the error
        console.error("Error message:", error.message);
      }
  
      // Navigate to home or another appropriate page
      // navigate("/");
    }
  };

  return (
    <section className="login-section">
      <div className="container">
        <div className="login-main-box flex-box space-between align-center">
          <div className="login-left-box">
            <div className="text-box">
              <h2>Create Your Account</h2>
              <p>
                Track your orders, receive exclusive offers, and stay updated with the latest from FXRY
              </p>
            </div>
          </div>
          <div className="login-right-box">
            <h1>Hello, Nice to see you <span><img style={{ width: '1rem', marginLeft: '0.5rem' }} className="smile-emoji" src="https://uploads-ssl.webflow.com/65bdf07c6d804afa72989a80/6684063dd1da58d0eae1dc1f_smile-emoji.webp" alt="smile emoji" /></span></h1>
            {/* <h6>Hello, Nice to see you</h6>{" "} */}
            <div className={`form-box ${getOtp ? "hide-form-box" : ""}`}>
              <div className="feild-box flex-box">
                <h4>Please enter your email</h4>
                <input
                  className="login-input save-info-btn"
                  value={customerNumber}
                  onChange={handleCustomerNumber}
                  required
                  type="email"
                  placeholder="e.g. someone@gmail.com"
                />{" "}
              </div>
              {errorInfo != null && <p className="para">{errorInfo}</p>}
              <button onClick={handleGetOtp} className="save-info-btn">
                Sign In
              </button>
            </div>
            <div
              className={`otp-box ${getOtp ? "show-otp-box" : ""}`}
              style={{ display: "none" }}
            >
              <div className="feild-box flex-box">
                <h4>Enter OTP</h4>
                <input
                  className="otp-input"
                  value={customerOtp}
                  onChange={handleCustomerOtp}
                  type="number"
                  placeholder="XY301100"
                />{" "}
                <button className="save-info-btn" onClick={() => customerLoginHandler()}>Submit</button>
              </div>
              {errorInfo != null && <p className="para" style={{color : "black", fontWeight : "bold", fontSize : "0.8rem"}}>{errorInfo}</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LogIn;