import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ordersIcon from '../../assets/img/menu-icon-1.webp'
import customersIcon from '../../assets/img/menu-icon-2.webp'
import addressIcon from '../../assets/img/menu-icon-3.webp'
import contactIcon from '../../assets/img/menu-icon-4.webp'
import fixersIcon from '../../assets/img/menu-icon-5.webp'
import editIcon from '../../assets/img/customer-edit-icon.png'
import customerImage from '../../assets/img/customer-img.webp'
import telIcon from '../../assets/img/tel-icon.webp'
import mailIcon from '../../assets/img/email-icon.webp'
import locationIcon from '../../assets/img/location-icon.webp'
import popupCloseIcon from '../../assets/img/popup-close-icon.webp';
import defaultImg from '../../assets/img/empty-img.webp';
import changeImg from '../../assets/img/user-change-img.webp';

function usePreviousLocation() {
    const location = useLocation();
    const prevLocationRef = useRef();
  
    useEffect(() => {
      prevLocationRef.current = location;
    }, [location]);
  
    return prevLocationRef.current;
  }

function MenuBox() {
    const navigate = useNavigate();
    const location = useLocation();
    const prevLocation = usePreviousLocation();
    const [user, setUsersData] = useState(() => (
        JSON.parse(localStorage.getItem("users")) || ""
    ))
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');
    const [selectedInnerTab, setSelectedInnerTab] = useState('');
    const [showMainPopup, setShowMainPopup] = useState(false);
    const [userImg, setUserImg] = useState('');
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const openMainPopup = () => {
        setShowMainPopup(true);
    };

    const closeMainPopup = () => {
        setShowMainPopup(false);
    };

    const handleChange = (e) => {
        setNewMobileNumber(e.target.value);
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        navigate(`/${tab}`, { state: {} });
        setIsMobileMenuOpen(false);
    };

    const handleInnerTabClick = (tab) => {
        setSelectedInnerTab(tab);
        navigate(`/${tab}`, { state: {} });
        setIsMobileMenuOpen(false);
    };

    const handleHamburgerClick = () => {
        setIsMobileMenuOpen(!isMenuOpen);
    };
    const handleHamburgerCloseClick = () => {
        setIsMobileMenuOpen(false);
    };

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const lastpathSegments = prevLocation?.pathname?.split('/');
        const tab = pathSegments[1];
        const lastab = lastpathSegments && lastpathSegments.length !=0 ?  lastpathSegments[1] : 'dashboard';
        console.log(lastab,'last')
        // console.log(location,'new')
        if(pathSegments?.length == 3){
            setSelectedInnerTab(lastab);
        } else {
            if (tab && (tab === '' || tab === 'past' || tab === 'canceled')) {
                setSelectedInnerTab(tab);
            }
    
            if (['payment-methods', 'my-address', 'contact-us', 'settings'].includes(tab)) {
                setSelectedTab(tab);
            }
        }
    }, [location.pathname]);


    return (
        <>
            <div className="mobile-menu-box flex-box space-between align-center">
                <div className="customer-name-box flex-box align-center">
                    <div className="img-box">
                        <img src={"https://fxrydatastorage.s3.amazonaws.com/images+(1).png"} alt="" />
                    </div>
                    <div className="text-box">
                        <h6>CUSTOMER NAME</h6>
                        <h4>{user?.name}</h4>
                    </div>
                </div>
                <div className="hamburger-box">
                    <div class="mobile-hamburger" onClick={handleHamburgerClick}>☰</div>
                </div>
            </div>
            <div className={`main-menu-box ${isMobileMenuOpen ? 'open-mobile-menu' : ''}`}>
                <div onClick={handleHamburgerCloseClick} className="mobile-close-btn-box">
                    ✕
                </div>
                {/* <div onClick={openMainPopup} className="edit-btn-box">
                    <img src={editIcon} alt="" />
                </div> */}
                {showMainPopup && (
                    <div className="popup-outer-box">
                        <div className="popup-box user-edit-popup">
                            <button className='popup-close-btn' onClick={closeMainPopup}><img src={popupCloseIcon} alt="" /></button>
                            <div className="popup-edit-box flex-box space-between">
                                {/* <div className="img-change-box relative">
                                    <img className='user-edit-img' src={userImg || defaultImg} alt="" />
                                    <img
                                        className='user-change-img'
                                        src={changeImg}
                                        alt=""
                                        onClick={() => document.getElementById('file-input').click()}
                                    />
                                    <input
                                        id="file-input"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (event) => {
                                                    const imageDataUrl = event.target.result;
                                                    setUserImg(imageDataUrl);
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />

                                </div> */}
                                <div className="user-text-box">
                                    <div className="field-box">
                                        <h6>Customer Name</h6>
                                        <h4>{user?.name}</h4>
                                    </div>
                                    <div className="field-box">
                                        <h6>Mobile </h6>
                                        <div className="input-box">
                                            <input type="number" placeholder='+91' value={user?.phone} onChange={handleChange} />
                                            <button>Verify</button>
                                        </div>
                                    </div>
                                    <div className="field-box">
                                        <h6>EMAIL ADDRESS</h6>
                                        <h4>{user?.email}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="address-edit-box field-box">
                                <h6>Adress</h6>
                                <input type="text" value={user?.address} placeholder='109 1st Ave S, Seattle, WA 98104, US' readOnly />
                            </div>
                            <button className='save-info-btn' >Save</button>

                        </div>
                    </div>
                )}
                <div className="customer-detail-box">
                    <div className="customer-name-box flex-box align-center">
                        <div className="img-box">
                            {user?.name ? (
                                <div className="initials-box">
                                    {user.name.charAt(0).toUpperCase()}
                                </div>
                            ) : (
                                <img src={"https://fxrydatastorage.s3.amazonaws.com/images+(1).png"} alt="" />
                            )}
                        </div>
                        <div className="text-box" style={{marginLeft : "0.2rem"}}>
                            <h6>CUSTOMER NAME</h6>
                            <h4>{user?.name}</h4>
                        </div>
                    </div>
                    <div className="customer-contact-detail-box">
                        <h4><span><img src={telIcon} alt="" /></span>{user?.phone}</h4>
                        <h4><span><img src={mailIcon} alt="" /></span>{user?.email}</h4>
                        {/* <h6>Primary</h6>
                        <h4><span><img src={locationIcon} alt="" /></span>{user?.address}</h4> */}
                    </div>
                </div>
                <h6 className="menu-sub-text">MAIN</h6>
                <ul className='menu-listing'>
                    <li className='order-main-tab'>
                        <div className={`tab-box ${isMenuOpen ? 'pre-selected' : selectedTab === '' ? 'grey-bg' : 'hide'}`} onClick={() => { handleMenuOpen(); handleTabClick('dashboard'); }}>
                            <h6><span><img src={ordersIcon} alt="" /></span>Orders</h6>
                        </div>
                        <div className={`menu-inner-listing ${isMenuOpen ? '' : 'full-height'}`}>
                            <div className={`menu-inner-tab-box open-tab ${selectedInnerTab === '' ? 'selected' : ''}`} onClick={() => handleInnerTabClick('dashboard')}>
                                <h6>Open</h6>
                            </div>
                            <div className={`menu-inner-tab-box  ${selectedInnerTab === 'past' ? 'selected' : ''}`} onClick={() => handleInnerTabClick('past')}>
                                <h6>Past</h6>
                            </div>
                            <div className={`menu-inner-tab-box  ${selectedInnerTab === 'canceled' ? 'selected' : ''}`} onClick={() => handleInnerTabClick('canceled')}>
                                <h6>Canceled</h6>
                            </div>
                        </div>
                    </li>
                    {/* <li>
                        <div className={`tab-box ${selectedTab === 'payment-methods' ? 'selected' : ''}`} onClick={() => handleTabClick('payment-methods')}>
                            <h6><span><img src={customersIcon} alt="" /></span>Payment Methods</h6>
                        </div>
                    </li> */}
                    {/* <li>
                        <div className={`tab-box ${selectedTab === 'my-address' ? 'selected' : ''}`} onClick={() => handleTabClick('my-address')}>
                            <h6><span><img src={addressIcon} alt="" /></span>My Address</h6>
                        </div>
                    </li> */}
                    <li>
                        <div className={`tab-box ${selectedTab === 'contact-us' ? 'selected' : ''}`} onClick={() => handleTabClick('contact-us')}>
                            <h6><span><img src={contactIcon} alt="" /></span>Contact Us</h6>
                        </div>
                    </li>
                    <li>
                        <div className={`tab-box ${selectedTab === 'settings' ? 'selected' : ''}`} onClick={() => handleTabClick('settings')}>
                            <h6><span><img src={fixersIcon} alt="" /></span>Settings</h6>
                        </div>
                    </li>

                </ul>
            </div>
        </>
    );
}

export default MenuBox;
