import React, { useState } from "react";
import infoIcon from "../../assets/img/info-icon.webp";
import pickupIcon from "../../assets/img/pickup-icon.webp";
import { useLocation } from "react-router-dom";
const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday, 5 represents Friday
  let pickupOffset = 0;

  // Determine the initial offset based on the delivery day
  switch (deliveryDay) {
    case 1: // Monday
      pickupOffset = 10;
      break;
    case 3: // Wednesday
      pickupOffset = 12;
      break;
    case 5: // Friday
      pickupOffset = 10;
      break;
    default:
      return null; // Handle other days if necessary
  }

  const pickupDate = new Date(deliveryDate);

  // Add offset to delivery date, skipping Saturdays and Sundays
  while (pickupOffset > 0) {
    pickupDate.setDate(pickupDate.getDate() + 1);
    const dayOfWeek = pickupDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
      pickupOffset--;
    }
  }

  return pickupDate.toISOString(); // Convert to ISO format for consistency
};

function hasCompleteEmailStatus(order) {
  return order?.email_status?.some((status) => status?.emailType === "Complete");
}

function hasWithFixerEmailStatus(order) {
  return order?.email_status?.some((status) => status?.emailType == "With Fixer");
}


function UserOrderDetailBox({ orderId, itemCount, amount, order, totalTax, deliveryInfo }) {

  let orderStatus = hasCompleteEmailStatus(order)
  let completedOrder = order?.email_status?.filter((status) => status?.emailType === "Complete");
  let withFixerStatus = hasWithFixerEmailStatus(order)
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const tobedeliverydate = calculatePickupDate(order?.collection_date)
  const toggleBoxVisibility = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  return (
    <div className="user-detail-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading f-20">Order Details</h6>
      </div>
      <ul className="user-detail-listing flex-box space-between">
        <li>
          <div className="text-box">
            <h6>Services Count: </h6>
            <h4>{itemCount}</h4>
          </div>
          <div className="text-box">
            <h6>ID: </h6>
            <h4>{orderId}</h4>
          </div>
          <div className="text-box relative">
            <h6>Amount: </h6>
            <h4 id="price-info" onClick={toggleBoxVisibility}>
              {amount}{" "}
              <span>
                <img src={infoIcon} alt="" />
              </span>
            </h4>
            <div className={`price-info-box ${isBoxVisible ? "show" : ""}`}>
              <div className="text-box">
                <h6>Order Amount</h6>
                <h4>-</h4>
                <h6>${amount}</h6>
              </div>
              <div className="text-box">
                <h6>10.35 % inclusive tax</h6>
                <h4>-</h4>
                <h6>${totalTax.toFixed(2)}</h6>
              </div>
              <div className="text-box">
                <h6>Platform Fee</h6>
                <h4>-</h4>
                <h6>$5.00</h6>
              </div>
              <div className="text-box">
                <h6>Applied Discount</h6>
                <h4>-</h4>
                <h6>$0.00</h6>
              </div>
            </div>
          </div>
          <div className="text-box">
            <h6>Address : </h6>
            {/* <h4>{order?.customer_details?.address}</h4> */}
            <h4>{order?.customer_details?.addresses[0]?.line1}, {order?.customer_details?.addresses[0]?.line2}, {order?.customer_details?.addresses[0]?.city} , {order?.customer_details?.addresses[0]?.code}</h4>
          </div>
        </li>
        <li className="track-your-order-box">
          <h4 className="text-center">Track Your Order</h4>
          <div className="inner-list-box flex-box space-between align-center">
            <div className="list-box">
              <div className="img-box">
                <img src={pickupIcon} alt="" />
              </div>
              <div className="list-text-box">
                <h4 className="relative">
                  Pickup <span class="status-dot green"></span>{" "}
                </h4>
                <h6>{orderStatus ? 'Order was picked up on' : 'Order will be Picked On'}</h6>
                <h5>
                  {new Date(order?.collection_date).toLocaleString("en-US", {
                    day: "numeric",
                    month: "long",
                    weekday: "long",
                  })}
                </h5>
              </div>
            </div>
            <div className="list-box">
              <div className="img-box">
                <img src={pickupIcon} alt="" />
              </div>
              <div className="list-text-box">
                <h4 className="relative">
                  We’re working on the fixing{" "}
                  <span class={`status-dot ${withFixerStatus ? 'green' : orderStatus ? 'green' : 'orange'}`}></span>{" "}
                </h4>
                <h6>Days needed</h6>
                <h5>10 to 12 working days</h5>
              </div>
            </div>
            <div className="list-box">
              <div className="img-box">
                <img src={pickupIcon} alt="" />
              </div>
              <div className="list-text-box">
                <h4 className="relative">Drop-off
                  <span class={`status-dot ${orderStatus ? 'green' : 'orange'}`}></span>{" "}
                </h4>
                <h6>{orderStatus ? 'Order Delivered on' : 'Estimated Delivery Date'}</h6>
                <h5>
                  {orderStatus && completedOrder && completedOrder.length != 0 ? new Date(completedOrder[0]?.sendDate).toLocaleString("en-US", { day: "numeric", month: "long", weekday: "long" }) : new Date(tobedeliverydate).toLocaleString("en-US", { day: "numeric", month: "long", weekday: "long" })}
                </h5>
                {/* <h5>{new Date(tobedeliverydate).toLocaleString("en-US", {
                    day: "numeric",
                    month: "long",
                    weekday: "long",
                  })}</h5> */}
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div style={{display : "flex", justifyContent : "end", flexWrap : "wrap"}}>
            <div className="field-box" style={{margin : "1rem"}}>
              <h6>Can we leave the order with a neighbor?</h6>
              <h4>{deliveryInfo?.leavewithneighbour == true ? "Yes" : "No" || "Yes"}</h4>
            </div>
            <div className="field-box" style={{margin : "1rem"}}>
              <h6>Info 1 (Is there a safe place to leave the delivery ?)</h6>
              <h4>{deliveryInfo?.basic_instruction}</h4>
            </div>
            <div className="field-box" style={{margin : "1rem"}}>
              <h6>Info 2 (Extra delivery instructions)</h6>
              <h4>{deliveryInfo?.extra_instruction}</h4>
            </div>
          </div>
    </div>
  );
}

export default UserOrderDetailBox;
